import { NhostClient } from "@nhost/nhost-js";
import { $fetch } from "ofetch";
import { debounce } from "@/utils/lang";

export const nhostClient = new NhostClient({
  subdomain: process.env.NEXT_PUBLIC_NHOST_SUBDOMAIN || "local",
  region: process.env.NEXT_PUBLIC_NHOST_REGION || "",
  //autoRefreshToken: true,
  refreshIntervalTime: 60 * 5, // 5 minutes,
  autoSignIn: true,
});

const sendSessionRequest = debounce(() => {
  $fetch("/api/session", {
    headers: {
      Authorization: `Bearer ${nhostClient.auth.getAccessToken()}`,
    },
  })
    .then()
    .catch(console.error);
}, 1000);
nhostClient.auth.onAuthStateChanged((state) => {
  if (state === "SIGNED_IN") {
    sendSessionRequest();
  }
});

export const useNhost = () => {
  return nhostClient;
};
