"use client";

import { OrgBottomMenu } from "@/components/sections/bottom-menu-org";
import Footer from "@/components/sections/footer";
import { OrgSideMenu } from "@/components/sections/side-menu-org";
import { AppLoadingScreen } from "@/components/ui-components/app-loading-screen";
import { ScrollToTopButton } from "@/components/ui-components/scroll-to-top-button";
import { ErrorBoundary } from "@sentry/nextjs";
import AppErrorBoundary from "@/components/common-components/app-error-boundary";
import { usePathname, useRouter } from "next/navigation";
import { OrgNotifications } from "@/components/sections/org-notifications";
import {
  useInitiateUserOrg,
  useInitiateUserSubscriptions,
  useUserOrg,
  useUserSubscriptions,
} from "@/lib/user/hooks";
import { NhostApolloProvider } from "@/nhost/provider";
import { useAuthenticationStatus, useUserData } from "@nhost/nextjs";
import { ThemeProvider } from "@mui/material";
import { ysMuiTheme } from "@/mui";
import { MessagesComponent } from "@/components/common-components/messages-component";
import { nhostClient } from "@/nhost";
import { ContactUsModal } from "@/components/subscriptions/contact-us-modal";

export default function DashboardLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const graphqlUrl = new URL(
    "/api/gql/v1",
    process.env.NEXT_PUBLIC_FRONTEND_URL
  );
  console.log("app/dashboard/layout");

  const { isAuthenticated, isLoading } = useAuthenticationStatus();
  const router = useRouter();
  const pathname = usePathname();

  console.log("isAtuhenticated", isAuthenticated);
  console.log("isLoading", isLoading);
  if (!isAuthenticated) {
    if (!isLoading) {
      const query = new URLSearchParams({ path: pathname });
      router.replace(`/app/auth/sign-in?${query.toString()}`);
      return <AppLoadingScreen />;
    }
  }

  return (
    <NhostApolloProvider
      nhost={nhostClient}
      graphqlUrl={graphqlUrl.toString()}
      headers={{
        // Nhost does not always set the access token in the graphql headers .
        // So it will say it's authenticed with useAuthenticationStatus, but the request will fail.
        authorization: `Bearer ${nhostClient.auth.getAccessToken()}`,
      }}
    >
      <LayoutContent>{children}</LayoutContent>
    </NhostApolloProvider>
  );
}

const LayoutContent = ({ children }: { children: React.ReactNode }) => {
  console.log("app/dashboard/layout content");
  const { initiate: initiateOrg } = useInitiateUserOrg();
  const { initiate: initiateSubscriptions } = useInitiateUserSubscriptions();
  const userData = useUserData();
  if (userData) {
    initiateOrg();
  }
  const userOrg = useUserOrg();
  if (userOrg.org?.id) {
    initiateSubscriptions({ orgId: userOrg.org.id });
  }
  const { subscriptionType, status: subscriptionsStatus } =
    useUserSubscriptions();
  const pathname = usePathname();

  if (
    userOrg.status !== "SUCCESS" ||
    subscriptionsStatus !== "SUCCESS" ||
    !userData
  ) {
    console.log("userOrg loading, AppLoadingScreen");
    console.log("userOrg.isLoading", userOrg.isLoading);
    console.log("userOrg.org", userOrg.org);
    return <AppLoadingScreen />;
  } else {
    const width = "1200px";

    if (typeof window !== "undefined") {
      const orgId = userOrg.org?.id;
      const userId = userData?.id;
      if (window.dataLayer && window.dataLayer.push) {
        window.dataLayer.push({
          ys_user_id: userId,
          ys_org_id: orgId,
        });
      }
      if (window.clarity && typeof window.clarity === "function") {
        console.log(`Setting clarity user id to ${userId?.replace(/\-/g, "")}`);
        window.clarity("identify", userId?.replace(/\-/g, ""));
        window.clarity("set", "ys_user_id", userId?.replace(/\-/g, ""));
        window.clarity("set", "ys_org_id", orgId?.replace(/\-/g, ""));
      }
      window.ysUserId = userId;
      window.ysOrgId = orgId;
    }

    const showNoSubscriptionOverlay =
      !["BROKER", "CENTRAL_AGENT", "CORPORATE"].includes(subscriptionType) &&
      !pathname.startsWith("/app/profile");
    /*if (subscriptionType === "NONE" && !pathname.startsWith("/app/profile")) {
      router.push("/app/profile");
      return <AppLoadingScreen />;
    }*/

    return (
      <div className="font-sans">
        <div className="min-h-screen bg-gray-100">
          <div className="flex pb-6">
            <ThemeProvider theme={ysMuiTheme}>
              <div className="w-36 mx-3 relative hidden lg:block">
                <div
                  className="sticky top-3 overflow-y-auto scrollbar-hidden rounded-xl pt-3"
                  style={{
                    maxHeight: "calc(100vh - 1.5rem)",
                  }}
                >
                  <OrgSideMenu />
                </div>
              </div>
              <div className="w-full fixed bottom-0 left-0 h-20 block lg:hidden z-40">
                <OrgBottomMenu />
              </div>
              <main className="px-2 mx-auto w-full" style={{ maxWidth: width }}>
                <ErrorBoundary
                  fallback={(sentryErrorFallbackProps) => (
                    <AppErrorBoundary {...sentryErrorFallbackProps} />
                  )}
                >
                  <div>
                    <div className="flex justify-end items-center px-2 pt-3">
                      <OrgNotifications />
                    </div>
                    <div className="relative px-2 py-3 min-h-[90vh]">
                      {children}
                      {(showNoSubscriptionOverlay && (
                        <NoSubscriptionOverlay />
                      )) ||
                        null}
                      <ScrollToTopButton />
                    </div>
                  </div>
                </ErrorBoundary>
              </main>
            </ThemeProvider>
          </div>
          <MessagesComponent />
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
};

const NoSubscriptionOverlay = () => {
  const { subscriptionType, isFreeTrialEnded } = useUserSubscriptions();

  if (["BROKER", "CENTRAL_AGENT", "CORPORATE"].includes(subscriptionType)) {
    return null;
  }
  return (
    <div className="w-full h-full absolute top-0 left-0 backdrop-blur-sm flex items-center justify-center">
      <div className="rounded-lg bg-b1 p-8 text-t1 max-w-3xl mx-auto">
        {(isFreeTrialEnded && (
          <>
            <div className="font-bold text-lg mb-2">
              Your free trial has ended.
            </div>
            <div className="mb-8">
              You don&apos;t currently have an active subscription attached to
              your account. <br />
              Add a subscription to your account or contact us on
              help@yachtsector.com if you have questions.
            </div>
            <div className="space-x-4">
              <ContactUsModal
                trigger={<button className="btn-primary">Contact us</button>}
              />
            </div>
          </>
        )) || (
          <>
            <div className="font-bold text-lg mb-2">
              No active subscription on your account
            </div>
            <div className="mb-8">
              You don&apos;t currently have an active subscription attached to
              your account. <br />
              Add a subscription to your account or contact us on
              help@yachtsector.com if you have questions.
            </div>
            <div className="space-x-4">
              <ContactUsModal
                trigger={<button className="btn-primary">Contact us</button>}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
