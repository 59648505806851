import { useRouter } from "next/navigation";
import React from "react";

export default function AppErrorBoundary({
  error,
  componentStack,
  resetError,
}) {
  const router = useRouter();
  const handleClickGoBack = () => {
    resetError();
    router.push("/app/auth/sign-in");
  };
  return (
    <div className="space-y-4">
      <div>You have encountered an error</div>
      <div>{error.toString()}</div>
      <div>
        <button className="btn-primary" onClick={handleClickGoBack}>
          Go back
        </button>
      </div>
    </div>
  );
}
