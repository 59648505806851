import { classes } from "@/utils/lang";
import { ExpandLessOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";

export const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  const handleClickButton = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div
      className={classes({
        "fixed bottom-24 lg:bottom-3 right-3": true,
        hidden: !showButton,
      })}
    >
      <button
        className="rounded-full p-4 bg-b2 bg-opacity-60 hover:bg-opacity-80 text-white"
        onClick={handleClickButton}
        name="Scroll to top"
      >
        <ExpandLessOutlined />
      </button>
    </div>
  );
};
