import { Facebook, Instagram, LinkedIn } from "@mui/icons-material";
import Link from "next/link";

const Footer = () => {
  return (
    <footer className="w-full bg-b1 p-4 text-t1 font-serif pt-12 pb-24 main-footer">
      <div className="lg:max-w-[1200px] px-4 mx-auto">
        <div className="grid lg:grid-cols-4 md:grid-cols-3 leading-8">
          <div className="mb-8 lg:mb-0">
            <div className="uppercase font-sans text-t4 lg:mb-16 mb-4 tracking-wide">
              Services
            </div>
            <div>
              <Link href="/brokers">
                <div>For brokers</div>
              </Link>
            </div>
            <div>
              <Link href="/central-agents">
                <div>For central agents</div>
              </Link>
            </div>
            <div>
              <Link href="/discover-landing">
                <div>Discover yachts</div>
              </Link>
            </div>
            <div>
              <Link href="/directory">
                <div>Experience directory</div>
              </Link>
            </div>
            <div>
              <Link href="/for-crew">
                <div>For crew</div>
              </Link>
            </div>
            <div>
              <Link href="/for-experiences">
                <div>For experiences</div>
              </Link>
            </div>
          </div>
          <div className="mb-8 lg:mb-0 hidden">
            <div className="uppercase font-sans text-t4 lg:mb-16 mb-4">
              Offices
            </div>
            <div className="w-40">
              Yacht Sector Limited Lansdowne House, 57 Berkeley Square, London
              W1J 6ER, Great Britain
            </div>
          </div>
          <div>
            <div className="uppercase font-sans text-t4 lg:mb-16 mb-4 tracking-wide">
              Support
            </div>
            <div>
              <Link href="/help-center">
                <div>Help center</div>
              </Link>
            </div>
            <div>
              <Link href="/contact">
                <div>Contact us</div>
              </Link>
            </div>
            <div>
              <Link href="/faq">
                <div>FAQ</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-48 text-gray-400 lg:flex lg:justify-between space-y-4 lg:space-y-0">
          <div className="flex lg:flex-row flex-col space-y-1 lg:space-y-0 lg:space-x-4">
            <div className="lg:mr-16">
              © {new Date().getFullYear()} - Yacht Sector
            </div>
            <Link href="/terms">
              <div>Terms of service</div>
            </Link>
            <Link href="/privacy">
              <div>Privacy policy</div>
            </Link>
          </div>
          <div className="flex space-x-2">
            <div>
              <a
                href="https://www.instagram.com/yacht_sector/"
                target="_blank"
                rel="noopener noreferrer"
                title="Instagram"
              >
                <Instagram />
              </a>
            </div>
            <div>
              <a
                href="https://www.facebook.com/yachtsectorsoftware"
                target="_blank"
                rel="noopener noreferrer"
                title="Facebook"
              >
                <Facebook />
              </a>
            </div>
            <div>
              <a
                href="https://www.linkedin.com/company/yacht-sector/"
                target="_blank"
                rel="noopener noreferrer"
                title="LinkedIn"
              >
                <LinkedIn />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
