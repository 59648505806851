import { useUserSubscriptions } from "@/lib/user/hooks";

export const getSubscriptionType = ({
  subscriptions,
}: {
  subscriptions: GQL_GET_ORG_SUBSCRIPTIONS_QUERY["org_subscriptions"];
}) => {
  let subscriptionType: SubscriptionType = "NONE";
  let subscriptionValidUntil;
  let maxYachtListings = 0;
  let isExpired = false;
  let isTrial = false;

  const validSubscriptions = (subscriptions || []).filter(
    (x) => x.valid_until && new Date(x.valid_until) > new Date()
  );
  const expiredSubscriptions = (subscriptions || []).filter(
    (x) => x.valid_until && new Date(x.valid_until) < new Date()
  );
  const expiredRoleSubscriptions = expiredSubscriptions.filter((x) =>
    ["BROKER", "CENTRAL_AGENT", "CORPORATE"].includes(x.plan)
  );
  if (expiredRoleSubscriptions.length > 0) {
    isExpired = true;
  }
  const expiredTrialSubscription = expiredSubscriptions.find(
    (x) =>
      x.plan === "BROKER" &&
      new Date(x.valid_until).getTime() - new Date(x.created_at).getTime() <=
        1000 * 60 * 60 * 24 * 14
  );

  if (expiredTrialSubscription) {
    isTrial = true;
  }

  if ((validSubscriptions || []).find((x) => x.plan === "CORPORATE")) {
    subscriptionType = "CORPORATE";
    subscriptionValidUntil = new Date(
      (validSubscriptions || []).find((x) => x.plan === "CORPORATE").valid_until
    );
    maxYachtListings += 20;
  } else if (
    (validSubscriptions || []).find((x) => x.plan === "CENTRAL_AGENT")
  ) {
    subscriptionType = "CENTRAL_AGENT";
    subscriptionValidUntil = new Date(
      (validSubscriptions || []).find(
        (x) => x.plan === "CENTRAL_AGENT"
      ).valid_until
    );
    maxYachtListings += 5;
  } else if ((validSubscriptions || []).find((x) => x.plan === "BROKER")) {
    subscriptionType = "BROKER";
    subscriptionValidUntil = new Date(
      (validSubscriptions || []).find((x) => x.plan === "BROKER").valid_until
    );
  }
  (validSubscriptions || [])
    .filter((x) => x.plan === "EXTRA_LISTING")
    .forEach((x) => {
      maxYachtListings += x.quantity;
    });

  return {
    maxYachtListings,
    subscriptionType,
    subscriptionValidUntil,
    isExpired,
    isTrial,
    isFreeTrialEnded: subscriptionType === "NONE" && isExpired && isTrial,
  };
};

export const useRoleOrFallback = ({ role }: { role: UserRole }): UserRole => {
  const { subscriptionType } = useUserSubscriptions();

  if (subscriptionType === "NONE") {
    return "user";
  } else {
    return role;
  }
};
