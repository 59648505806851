import { gql, useSubscription } from "@apollo/client";
import { NotificationsNone } from "@mui/icons-material";
import Link from "next/link";
import { useUserInformation } from "@/lib/user/hooks";

const NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription NOTIFICATIONS_SUBSCRIPTION($org_id: uuid!) {
    org_notifications(
      where: { org: { _eq: $org_id }, status: { _eq: "INITIAL" } }
    ) {
      id
    }
  }
`;

export const OrgNotifications = () => {
  return (
    <div className="h-12">
      <NotificationsWithWs />
    </div>
  );
};

const NotificationsWithWs = () => {
  const userInformation = useUserInformation();
  const {
    data: notificationsSubscriptionData,
    loading: notificationsSubscriptionLoading,
    error: notificationsSubscriptionError,
  } = useSubscription(NOTIFICATIONS_SUBSCRIPTION, {
    variables: {
      org_id: userInformation.userOrg?.id,
    },
  });

  if (notificationsSubscriptionError) {
    console.log(notificationsSubscriptionError);
  }

  if (!notificationsSubscriptionData) {
    return null;
  }

  const notifications = notificationsSubscriptionData.org_notifications;

  return (
    <div className="mb-2">
      <Link href="/app/notifications" title="Notifications">
        {(notifications.length === 0 && (
          <div className="p-2 bg-gray-400 rounded shadow text-white text-opacity-90 font-sans">
            <NotificationsNone />
          </div>
        )) || (
          <div className="p-2 bg-orange-400 rounded shadow text-white text-opacity-90 font-sans">
            <NotificationsNone /> {notifications.length} Unread
          </div>
        )}
      </Link>
    </div>
  );
};
