"use client";
import { defaultYachtImageUrl } from "@/constants";
import { useUser } from "@/lib/user/hooks";
import { useEffect, useState } from "react";
import { omit } from "lodash";

type GetCfUrlForImageProps = {
  imageUrl: string;
  width?: number;
  height?: number;
  format?: string;
  fit?: string;
};
const getCfUrlForImage = ({
  imageUrl,
  width,
  height,
  format,
  fit,
}: GetCfUrlForImageProps) => {
  if (process.env.NEXT_PUBLIC_NHOST_SUBDOMAIN === "local") {
    return imageUrl;
  }
  const cfImageParams = [];
  if (width) {
    cfImageParams.push(`width=${width}`);
  }
  if (height) {
    cfImageParams.push(`height=${height}`);
  }
  if (format) {
    cfImageParams.push(`format=${format}`);
  }
  if (fit) {
    cfImageParams.push(`fit=${fit}`);
  }

  return `https://images.yachtsector.com/cdn-cgi/image/${cfImageParams.join(
    ","
  )}/${imageUrl}`;
};

type PresignedImageProps = Omit<import("next/image").ImageProps, "src"> & {
  fileId: string;
  public?: boolean;
  width?: number;
  height?: number;
  defaultSrc?: string;
  fit?: string;
  format?: string;
  useCf?: boolean;
  animateLoading?: boolean;
};

export const PresignedImage = (props: PresignedImageProps) => {
  const userContext = useUser();

  const [presignedUrl, setPresignedUrl] = useState<string | null>(null);

  useEffect(() => {
    const setPresignedUrlAsync = async () => {
      const presignedImageResponse =
        await userContext.nhost?.storage.getPresignedUrl({
          fileId: props.fileId,
        });
      if (presignedImageResponse?.error) {
        console.warn(presignedImageResponse?.error);
      } else if (presignedImageResponse?.presignedUrl?.url) {
        if (props.width) {
          setPresignedUrl(
            getCfUrlForImage({
              imageUrl: presignedImageResponse.presignedUrl.url,
              ...props,
            })
          );
        } else {
          setPresignedUrl(presignedImageResponse.presignedUrl.url);
        }
      }
    };
    if (props.fileId && props.public !== true) {
      setPresignedUrlAsync();
    }
  }, [props.fileId]);

  let imageUrl: string | URL = props.defaultSrc || defaultYachtImageUrl;

  if (props.public && props.fileId) {
    // Not set Nhost params if have to use CF
    // Nhost image resize is a bit buggy, and might crash if resizing too
    // many images at once, so we just use CF for all images
    if (props.fit || props.format || props.useCf || true) {
      const publicUrl = userContext.nhost?.storage.getPublicUrl({
        fileId: props.fileId,
      });
      if (publicUrl) {
        imageUrl = getCfUrlForImage({ imageUrl: publicUrl, ...props });
      }
    } else {
      const nhostImageArgs: { height?: number; width?: number } = {};
      if (props.width) {
        nhostImageArgs.width = props.width;
      }
      if (props.height) {
        nhostImageArgs.height = props.height;
      }
      const publicUrl = userContext.nhost?.storage.getPublicUrl({
        fileId: props.fileId,
        ...nhostImageArgs,
      });
      if (publicUrl) {
        imageUrl = publicUrl;
      }
    }
  } else if (presignedUrl) {
    imageUrl = presignedUrl;
  }

  const handleImageOnLoad = (event) => {
    const imageElement: HTMLImageElement = event.target;

    if (
      imageElement.naturalHeight &&
      imageElement.naturalHeight > imageElement.naturalWidth
    ) {
      imageElement.classList.add("object-cover");
      imageElement.classList.remove("object-contain");
    }
    imageElement.classList.remove("bg-gray-200");
    imageElement.classList.remove("animate-pulse");
  };

  let className = props.className || "";

  if (props.animateLoading) {
    className += " bg-gray-200 animate-pulse";
  }

  return (
    <img
      {...{
        ...omit(props, [
          "fileId",
          "public",
          "defaultSrc",
          "className",
          "animateLoading",
        ]),
        className,
      }}
      src={imageUrl}
      onLoad={handleImageOnLoad}
    />
  );
};
