import { gql } from "@apollo/client";
import { atom, useAtom } from "jotai";
import { getSubscriptionType } from "@/utils/orgs";
import { useNhost } from "@/nhost";

// Must be initiated
type OrgAtomType = {
  status: "SUCCESS";
  isLoading?: boolean;
  isError?: boolean;
  userProfile?: GQL_GET_USER_PROFILE_WITH_ORG_QUERY["user_profiles"][0];
  orgUser?: Exclude<
    GQL_GET_USER_PROFILE_WITH_ORG_QUERY["user_profiles"][0]["org_user"],
    null
  >;
  org?: Exclude<
    GQL_GET_USER_PROFILE_WITH_ORG_QUERY["user_profiles"][0]["org_user"],
    null
  >["central_agent_org"];
};
// Must be initiated
type UserInformationAtomType = {
  status: "SUCCESS";
  isLoading?: boolean;
  isError?: boolean;
  userProfile?: GQL_GET_USER_PROFILE_WITH_ORG_QUERY["user_profiles"][0];
  orgUser?: Exclude<
    GQL_GET_USER_PROFILE_WITH_ORG_QUERY["user_profiles"][0]["org_user"],
    null
  >;
  org?: Exclude<
    GQL_GET_USER_PROFILE_WITH_ORG_QUERY["user_profiles"][0]["org_user"],
    null
  >["central_agent_org"];
};
// Must be initiated
type SubscriptionsAtomType = {
  status: "SUCCESS";
  loading?: boolean;
  error?: boolean;
  maxYachtListings: number;
  subscriptionType: string;
  subscriptionValidUntil?: Date;
  isTrial: boolean;
  isExpired: boolean;
  isFreeTrialEnded: boolean;
};

const userOrgAtom = atom<
  OrgAtomType | { status: "INITIAL" | "LOADING" | "ERROR" }
>({ status: "INITIAL" });
const userInformationAtom = atom<
  OrgAtomType | { status: "INITIAL" | "LOADING" | "ERROR" }
>({ status: "INITIAL" });
const userSubscriptionsAtom = atom<
  SubscriptionsAtomType | { status: "INITIAL" | "LOADING" | "ERROR" }
>({ status: "INITIAL" });

export const useUserOrg = (): OrgAtomType => {
  const [result] = useAtom(userOrgAtom);
  return result;
};

export const useUserSubscriptions = (): SubscriptionsAtomType => {
  const [result] = useAtom(userSubscriptionsAtom);
  return result;
};

export const useRefetchUserOrg = () => {
  const nhostClient = useNhost();
  const [atomData, setAtom] = useAtom(userOrgAtom);
  const handleFetch = async () => {
    const user = nhostClient.auth.getUser();
    if (!user) {
      throw new Error("No user found");
    }
    const response = await nhostClient.graphql.request<
      GQL_GET_USER_PROFILE_WITH_ORG_QUERY,
      GQL_GET_USER_PROFILE_WITH_ORG_QUERY_VARIABLES
    >(
      gql`
        query GET_USER_PROFILE_WITH_ORG($user_id: uuid!) {
          user_profiles(where: { user: { _eq: $user_id } }) {
            id
            title
            profile_image
            org_user {
              id
              central_agent_org {
                id
                title
                details
              }
            }
          }
        }
      `,
      { user_id: user.id }
    );
    if (!response.data?.user_profiles[0]) {
      console.warn("No org found for user", user.id);
      throw new Error("No org found for user");
    }
    const userProfile = response.data.user_profiles[0];
    const orgUser = userProfile?.org_user;
    const org = orgUser?.central_agent_org;
    console.log("Setting orgAtom");
    console.log(org);
    setAtom({
      status: "SUCCESS",
      isLoading: false,
      isError: false,
      userProfile,
      orgUser,
      org,
    });
  };
  return { refetch: handleFetch, status: atomData.status };
};

export const useInitiateUserOrg = () => {
  const [atomData, setAtom] = useAtom(userOrgAtom);
  const { refetch: handleFetch } = useRefetchUserOrg();

  const initiate = async () => {
    if (atomData.status !== "INITIAL") {
      return;
    }
    setAtom({ status: "LOADING" });
    try {
      console.log("Fetching org in initiate");
      await handleFetch();
    } catch (e) {
      console.error(e);
      setAtom({ status: "ERROR" });
    }
  };
  return { initiate, status: atomData.status };
};

export const useRefetchUserSubscriptions = () => {
  const nhostClient = useNhost();
  const [atomData, setAtom] = useAtom(userSubscriptionsAtom);
  const handleFetch = async ({ orgId }: { orgId: string }) => {
    const thirtyDaysPast = new Date(
      new Date().getTime() - 1000 * 60 * 60 * 24 * 30
    );
    const response = await nhostClient.graphql.request<
      GQL_GET_ORG_SUBSCRIPTIONS_QUERY,
      GQL_GET_ORG_SUBSCRIPTIONS_QUERY_VARIABLES
    >(
      gql`
        query GET_ORG_SUBSCRIPTIONS($org_id: uuid!, $now: timestamptz!) {
          org_subscriptions(
            where: {
              valid_until: { _gte: $now }
              central_agent_org: { _eq: $org_id }
            }
          ) {
            id
            status
            valid_until
            stripe_subscription_id
            plan
            quantity
            created_at
          }
        }
      `,
      { org_id: orgId, now: thirtyDaysPast }
    );
    if (!response.data) {
      console.warn("No org found for org", orgId);
      throw new Error("No org found for org");
    }

    const subscriptions = response.data.org_subscriptions;
    const {
      maxYachtListings,
      subscriptionType,
      subscriptionValidUntil,
      isExpired,
      isTrial,
      isFreeTrialEnded,
    } = getSubscriptionType({
      subscriptions: subscriptions || [],
    });

    console.log("Setting subscriptions atom");
    setAtom({
      status: "SUCCESS",
      loading: false,
      error: false,
      subscriptions,
      maxYachtListings,
      subscriptionType,
      subscriptionValidUntil,
      isTrial,
      isExpired,
      isFreeTrialEnded,
    });
  };
  return { refetch: handleFetch, status: atomData.status };
};

export const useInitiateUserSubscriptions = () => {
  const [atomData, setAtom] = useAtom(userSubscriptionsAtom);
  const { refetch: handleFetch } = useRefetchUserSubscriptions();

  const initiate = async ({ orgId }: { orgId: string }) => {
    if (atomData.status !== "INITIAL") {
      return;
    }
    setAtom({ status: "LOADING" });
    try {
      await handleFetch({ orgId });
    } catch (e) {
      console.error(e);
      setAtom({ status: "ERROR" });
    }
  };
  return { initiate, status: atomData.status };
};

export const useUserInformation = () => {
  const org = useUserOrg();

  return {
    userOrg: org.org,
    userProfile: org.userProfile,
    userOrgLoadingStatus: org.status,
    userOrgError: org.isError,
    currentOrgUser: org.orgUser,
  };
};

export const useUser = () => {
  const nhostClient = useNhost();
  return {
    user: null,
    accessToken: null,
    nhost: nhostClient,
  };
};
