import { useState } from "react";
import { YsModalWithEventListener } from "../ui-components/ys-modal";
import EventTarget from "@ungap/event-target";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { useUserData } from "@nhost/react";
import { useAddMessage } from "@/store/messages";

type ContactMessageFormData = {
  message: string;
};

export const ContactUsModal = ({
  trigger,
}: {
  trigger: React.ReactElement;
}) => {
  const [submitState, setSubmitState] = useState("INITIAL");
  const eventTarget = new EventTarget();
  const { email } = useUserData();
  const addMessage = useAddMessage();

  const [insertMessage] = useMutation(gql`
    mutation INSERT_EMAIL_SUBMISSION_MESSAGE(
      $message: String!
      $email: String!
    ) {
      insert_email_submissions_one(
        object: { message: $message, email: $email }
      ) {
        id
      }
    }
  `);

  const handleClose = () => {
    eventTarget.dispatchEvent(new Event("close"));
    setTimeout(() => {
      setSubmitState("INITIAL");
    }, 200);
  };

  const handleSubmitForm = async (formData: ContactMessageFormData) => {
    console.log("formData");
    console.log(formData);
    setSubmitState("LOADING");
    const insertResponse = await insertMessage({
      variables: { message: formData.message, email },
    });

    if (insertResponse.errors) {
      setSubmitState("ERROR");
      addMessage({
        type: "ERROR",
        text: "Could not send your message. Please try again or contact us on email.",
      });
    } else {
      addMessage({
        type: "SUCCESS",
        text: "Your message was sent successfully.",
      });
      setSubmitState("SUCCESS");
    }
  };

  const form = useForm<ContactMessageFormData>({
    defaultValues: { message: "" },
  });

  return (
    <div>
      <YsModalWithEventListener
        eventTarget={eventTarget}
        trigger={trigger}
        header="Contact Us"
        maxWidth="md"
      >
        <div className="w-full sm:w-[512px]">
          <div className="py-4">
            {(submitState === "SUCCESS" && (
              <div className="text-black flex flex-col space-y-8">
                <div className="space-y-4">
                  <div className="text-lg">Your message is sent</div>
                  We will get back to you as soon as possible.
                </div>
                <div>
                  <button className="btn-black" onClick={handleClose}>
                    Close
                  </button>
                </div>
              </div>
            )) || (
              <div className="space-y-4">
                <div className="space-y-2 text-black">
                  <div>
                    Please leave your message below, we’ll get back to you soon.
                  </div>
                </div>
                <form
                  onSubmit={form.handleSubmit(handleSubmitForm)}
                  id="contact-us-form"
                >
                  <Controller
                    name="message"
                    control={form.control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        className="w-full"
                        value={value}
                        onChange={onChange}
                        label="Message"
                        error={!!form.formState.errors.message}
                        rows={4}
                        multiline
                      />
                    )}
                  />
                </form>
                <div className="flex space-x-4 items-center">
                  <button
                    className="btn-black btn-loading"
                    type="submit"
                    form="contact-us-form"
                    data-loading={submitState === "LOADING"}
                    disabled={submitState === "LOADING"}
                  >
                    Send
                  </button>
                  <button className="px-4 py-2 font-bold" onClick={handleClose}>
                    Cancel
                  </button>
                </div>
                <div className="opacity-80 mt-8">
                  Or contact us on{" "}
                  <a className="underline" href="mailto:help@yachtsector.com">
                    help@yachtsector.com
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </YsModalWithEventListener>
    </div>
  );
};
