import Link from "next/link";
import { PresignedImage } from "@/components/media/presigned-image";
import {
  AddCircleOutlineOutlined,
  AddCommentOutlined,
  AdminPanelSettingsOutlined,
  AnchorOutlined,
  AutoAwesomeMosaicOutlined,
  DescriptionOutlined,
  DirectionsBoatFilledOutlined,
  ExploreOutlined,
  FormatListBulletedOutlined,
  LocalOfferOutlined,
  LogoutOutlined,
  MoreVert,
  NoteAltOutlined,
  SearchOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { LogoSquare } from "@/components/common-components/logo-square";
import { classes } from "@/utils/lang";
import defaultProfileImage from "@@/public/images/gray-bg-person.png";
import { useUserData } from "@nhost/react";
import { usePathname } from "next/navigation";
import { useUserInformation, useUserSubscriptions } from "@/lib/user/hooks";

const BottomBar = ({ isExpanded, setIsExpanded }) => {
  const pathname = usePathname();

  return (
    <div className="text-t1 text-center flex justify-around items-center h-full">
      <Link href={`/app/overview`}>
        <div
          className={classes({
            "opacity-60": !pathname.startsWith("/app/overview"),
          })}
        >
          <div className="text-2xl">
            <AutoAwesomeMosaicOutlined />
          </div>
          <div>Overview</div>
        </div>
      </Link>
      <Link href="/app/broker/clients">
        <div
          className={classes({
            "opacity-60": !pathname.startsWith("/app/broker/clients"),
          })}
        >
          <div className="text-2xl">
            <FormatListBulletedOutlined />
          </div>
          <div>
            <span>Client list</span>
          </div>
        </div>
      </Link>
      <Link href="/app/broker/requests">
        <div
          className={classes({
            "opacity-60": !pathname.startsWith("/app/broker/requests"),
          })}
        >
          <div className="text-2xl">
            <AddCircleOutlineOutlined />
          </div>
          <div>
            <span>Requests</span>
          </div>
        </div>
      </Link>
      <div role="button" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="text-2xl">
          <MoreVert />
        </div>
        <div>
          <span>More</span>
        </div>
      </div>
    </div>
  );
};

export const OrgBottomMenu = () => {
  const pathname = usePathname();
  const { subscriptionType } = useUserSubscriptions();
  const userInformation = useUserInformation();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(false);
  }, [pathname]);

  const isOnlyBroker = !["CENTRAL_AGENT", "CORPORATE"].includes(
    subscriptionType
  );
  const user = useUserData();
  const isAdmin = user?.roles.includes("admin");

  return (
    <nav className="h-full">
      <div className="h-full pb-1 shadow">
        <div className="rounded-xl mx-1 bg-b1 h-full border border-[#e0e0e055]">
          <BottomBar isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
        </div>
      </div>
      {isExpanded && (
        <div className="fixed overflow-y-scroll top-0 left-0 w-[100vw] h-[calc(100vh-5rem)] bg-b1">
          <div className="w-full fixed bottom-0 left-0 h-20 block lg:hidden z-40 bg-b1">
            <div className="h-full pb-1">
              <div className="border border-[#e0e0e055] rounded-xl mx-1 h-full">
                <BottomBar
                  isExpanded={isExpanded}
                  setIsExpanded={setIsExpanded}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="bg-b1 text-t1 py-6 px-3">
              <div className="flex flex-col items-center space-y-4 text-center">
                <Link href="/">
                  <div className="text-3xl h-16 w-16">
                    <div className="h-16 w-16">
                      <LogoSquare width={64} height={64} title="Home" />
                    </div>
                  </div>
                </Link>
                {isAdmin && (
                  <Link href={`/app/admin`}>
                    <div>
                      <div className="text-xl mb-2">
                        <AdminPanelSettingsOutlined />
                      </div>
                      <div>Admin</div>
                    </div>
                  </Link>
                )}
                <Link href={`/app/notes`}>
                  <div>
                    <div className="text-2xl">
                      <NoteAltOutlined />
                    </div>
                    <div>Notes</div>
                  </div>
                </Link>
                <Link href={`/app/discover`}>
                  <div>
                    <div className="text-2xl">
                      <SearchOutlined />
                    </div>
                    <div>Discover yachts</div>
                  </div>
                </Link>
                {/*<Link href="/app/experience-directory">
                  <div>
                    <div className="text-3xl mb-2">
                      <LocalActivityOutlined />
                    </div>
                    <div>
                      <span>Experience directory</span>
                    </div>
                  </div>
                </Link>*/}
                <Link href="/app/broker/active-charters">
                  <div>
                    <div className="text-3xl mb-2">
                      <ExploreOutlined />
                    </div>
                    <div>
                      <span>Active charters</span>
                    </div>
                  </div>
                </Link>
                {!isOnlyBroker && (
                  <Link href="/app/central-agent/listings">
                    <div>
                      <div className="text-3xl mb-2">
                        <DirectionsBoatFilledOutlined />
                      </div>
                      <div>Fleet management</div>
                    </div>
                  </Link>
                )}
                {!isOnlyBroker && (
                  <Link href="/app/central-agent/enquiries">
                    <div>
                      <div className="text-3xl mb-2">
                        <AddCommentOutlined />
                      </div>
                      <div>Enquiries</div>
                    </div>
                  </Link>
                )}
                <Link href="/app/contracts">
                  <div>
                    <div className="text-3xl mb-2">
                      <DescriptionOutlined />
                    </div>
                    <div>Contracts</div>
                  </div>
                </Link>
                <Link href="/app/find-crew">
                  <div>
                    <div className="text-3xl mb-2">
                      <AnchorOutlined />
                    </div>
                    <div>Find crew</div>
                  </div>
                </Link>
                <Link href="/app/brokerage">
                  <div>
                    <div className="text-3xl mb-2">
                      <LocalOfferOutlined />
                    </div>
                    <div>Yachts for sale</div>
                  </div>
                </Link>
                <div className="my-4"></div>
                <div>
                  <Link href="/app/profile">
                    <div>
                      <div className="h-20 w-20 mb-4 bg-white rounded-full">
                        {
                          <PresignedImage
                            public
                            fileId={userInformation?.userProfile?.profile_image}
                            className="rounded-full h-full w-full object-cover object-center"
                            defaultSrc={defaultProfileImage.src}
                            alt="Personal profile image"
                          />
                        }
                      </div>
                      <div style={{ transform: "rotate(90deg)" }}>&#10093;</div>
                    </div>
                  </Link>
                </div>
                <Link href="/app/profile">
                  <div>
                    <div className="text-3xl mb-2">
                      <SettingsOutlined />
                    </div>
                    <div>Settings</div>
                  </div>
                </Link>
                <Link href="/app/auth/post-sign-out">
                  <div>
                    <div className="text-3xl mb-2">
                      <LogoutOutlined />
                    </div>
                    <div>Log out</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};
