"use client";
import { useEffect, useState } from "react";
import { Dialog, DialogTitle } from "@mui/material";
import { Close } from "@mui/icons-material";
import { isMobileScreen } from "@/utils/device";

type UseModalParams = {
  header?: string;
  HeaderElement?: React.ReactElement;
  Trigger: () => React.ReactElement;
  Body: () => React.ReactElement;
  autoOpen?: boolean;
  maxWidth?: string;
  width?: string;
  onClose?: Function;
};

export const useModal = ({
  header,
  HeaderElement,
  Trigger,
  Body,
  autoOpen = false,
  maxWidth = "sm",
}: UseModalParams): [() => React.ReactElement, () => void, () => void] => {
  const [open, setOpen] = useState(autoOpen);

  const handleClickOpen = () => {
    if (!open) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (open) {
      setOpen(false);
    }
  };

  const isMobile = isMobileScreen();

  return [
    () => (
      <div>
        <div>
          <div role="button" onClick={handleClickOpen}>
            <Trigger />
          </div>
          <Dialog
            onClose={handleClose}
            open={open}
            maxWidth={isMobile ? false : maxWidth}
            fullScreen={isMobile}
          >
            <DialogTitle className="flex justify-between text-base sticky w-full z-10 items-center h-16 bg-white top-0">
              {HeaderElement || <span>{header}</span>}
              <button onClick={handleClose} type="button">
                <Close />
              </button>
            </DialogTitle>
            <div className="px-2 py-4 lg:p-4">
              <Body />
            </div>
          </Dialog>
        </div>
      </div>
    ),
    handleClose,
    handleClickOpen,
  ];
};

type YsModalProps = {
  header: string;
  children: React.ReactElement;
  trigger: React.ReactElement;
  maxWidth?: string;
};

export const YsModal = ({
  header,
  children,
  trigger,
  maxWidth = "sm",
}: YsModalProps) => {
  const [Modal] = useModal({
    header,
    Trigger: () => trigger,
    Body: () => children,
    maxWidth,
  });

  return <Modal />;
};

export const YsModalWithEventListener = ({
  header,
  HeaderElement,
  trigger,
  children,
  autoOpen = false,
  maxWidth = "sm",
  eventTarget,
  onClose = () => {},
}: UseModalParams) => {
  const [open, setOpen] = useState(autoOpen);

  const handleClickOpen = () => {
    if (!open) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (open) {
      setOpen(false);
    }
    onClose();
  };

  const isMobile = typeof window !== "undefined" && window.screen.width <= 768;

  eventTarget.addEventListener("close", () => handleClose());
  eventTarget.addEventListener("open", () => handleClickOpen());

  useEffect(() => {
    return () => {
      eventTarget.removeEventListener("close", () => {});
      eventTarget.removeEventListener("open", () => {});
    };
  }, []);

  return (
    <div>
      <div>
        <div role="button" onClick={handleClickOpen}>
          {trigger}
        </div>
        <Dialog
          onClose={handleClose}
          open={open}
          maxWidth={isMobile ? false : maxWidth}
          fullScreen={isMobile}
        >
          <DialogTitle className="flex justify-between text-base">
            {HeaderElement || <span>{header}</span>}
            <button onClick={handleClose}>
              <Close />
            </button>
          </DialogTitle>
          <div className="px-2 py-4 lg:p-4">{children}</div>
        </Dialog>
      </div>
    </div>
  );
};
export const YsModalWithEventListenerPurple = ({
  header,
  HeaderElement,
  trigger,
  children,
  autoOpen = false,
  maxWidth = "sm",
  eventTarget,
}: UseModalParams) => {
  const [open, setOpen] = useState(autoOpen);

  const handleClickOpen = () => {
    if (!open) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (open) {
      setOpen(false);
    }
  };

  const isMobile = typeof window !== "undefined" && window.screen.width <= 768;

  eventTarget.addEventListener("close", () => handleClose());
  eventTarget.addEventListener("open", () => handleClickOpen());

  useEffect(() => {
    return () => {
      eventTarget.removeEventListener("close", () => {});
      eventTarget.removeEventListener("open", () => {});
    };
  }, []);

  return (
    <div>
      <div>
        <div role="button" onClick={handleClickOpen}>
          {trigger}
        </div>
        <Dialog
          onClose={handleClose}
          open={open}
          maxWidth={isMobile ? false : maxWidth}
          fullScreen={isMobile}
        >
          <div className="h-screen lg:h-auto bg-b1">
            <DialogTitle className="flex justify-between text-base bg-b1 text-t1">
              {HeaderElement || <span>{header}</span>}
              <button
                onClick={handleClose}
                className="border rounded-full p-1 h-10 w-10 flex items-center justify-center opacity-60"
              >
                <Close />
              </button>
            </DialogTitle>
            <div className="p-2 lg:p-4 bg-b1 text-t1">{children}</div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};
