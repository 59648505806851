import Link from "next/link";
import { usePathname } from "next/navigation";
import { PresignedImage } from "@/components/media/presigned-image";
import {
  AddCircleOutlineOutlined,
  AddCommentOutlined,
  AdminPanelSettingsOutlined,
  AnchorOutlined,
  AutoAwesomeMosaicOutlined,
  DescriptionOutlined,
  DirectionsBoatFilledOutlined,
  ExploreOutlined,
  FormatListBulletedOutlined,
  LocalOfferOutlined,
  LogoutOutlined,
  NoteAltOutlined,
  SearchOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { classes } from "@/utils/lang";
import { LogoSquare } from "@/components/common-components/logo-square";
import defaultProfileImage from "@@/public/images/gray-bg-person.png";
import { useUserData } from "@nhost/react";
import { useUserInformation, useUserSubscriptions } from "@/lib/user/hooks";

export const OrgSideMenu = () => {
  const pathname = usePathname();
  const { subscriptionType } = useUserSubscriptions();
  const userInformation = useUserInformation();
  const isOnlyBroker = !["CENTRAL_AGENT", "CORPORATE"].includes(
    subscriptionType
  );
  const user = useUserData();
  const isAdmin = user?.roles.includes("admin");

  return (
    <nav>
      <div className="bg-b1 text-t1 rounded-xl py-6 px-3 text-sm">
        <div className="flex flex-col items-center space-y-4 text-center">
          <Link href="/">
            <div className="text-xl h-16 w-16">
              <LogoSquare width={64} height={64} title="Home" />
            </div>
          </Link>
          <div className="h-px w-full bg-white bg-opacity-20"></div>
          {isAdmin && (
            <Link href={`/app/admin`}>
              <div
                className={classes({
                  "opacity-60": !pathname.startsWith("/app/admin"),
                })}
              >
                <div className="text-xl mb-2">
                  <AdminPanelSettingsOutlined />
                </div>
                <div>Admin</div>
              </div>
            </Link>
          )}
          <Link href={`/app/overview`}>
            <div
              className={classes({
                "opacity-60": !pathname.startsWith("/app/overview"),
              })}
            >
              <div className="text-xl mb-2">
                <AutoAwesomeMosaicOutlined />
              </div>
              <div>Overview</div>
            </div>
          </Link>
          <Link href={`/app/notes`}>
            <div
              className={classes({
                "opacity-60": !pathname.startsWith("/app/notes"),
              })}
            >
              <div className="text-xl mb-2">
                <NoteAltOutlined />
              </div>
              <div>Notes</div>
            </div>
          </Link>
          <Link href={`/app/discover`}>
            <div
              className={classes({
                "opacity-60": !pathname.startsWith("/app/discover"),
              })}
            >
              <div className="text-xl mb-2">
                <SearchOutlined />
              </div>
              <div>Discover yachts</div>
            </div>
          </Link>
          <Link href="/app/broker/clients">
            <div
              className={classes({
                "opacity-60": !pathname.startsWith("/app/broker/clients"),
              })}
            >
              <div className="text-xl mb-2">
                <FormatListBulletedOutlined />
              </div>
              <div>
                <span>Client list</span>
              </div>
            </div>
          </Link>
          <Link href="/app/broker/requests">
            <div
              className={classes({
                "opacity-60": !pathname.startsWith("/app/broker/requests"),
              })}
            >
              <div className="text-xl mb-2">
                <AddCircleOutlineOutlined />
              </div>
              <div>
                <span>Requests</span>
              </div>
            </div>
          </Link>
          {/*<Link href="/app/experience-directory">
            <div
              className={classes({
                "opacity-60": !pathname.startsWith(
                  "/app/experience-directory"
                ),
              })}
            >
              <div className="text-xl mb-2">
                <LocalActivityOutlined />
              </div>
              <div>
                <span>Experience directory</span>
              </div>
            </div>
            </Link>*/}
          <Link href="/app/broker/active-charters">
            <div
              className={classes({
                "opacity-60": !pathname.startsWith(
                  "/app/broker/active-charters"
                ),
              })}
            >
              <div className="text-xl mb-2">
                <ExploreOutlined />
              </div>
              <div>
                <span>Active charters</span>
              </div>
            </div>
          </Link>
          <div className="h-px w-full bg-white bg-opacity-20"></div>
          {!isOnlyBroker && (
            <Link href="/app/central-agent/listings">
              <div
                className={classes({
                  "opacity-60": !pathname.startsWith(
                    "/app/central-agent/listings"
                  ),
                })}
              >
                <div className="text-xl mb-2">
                  <DirectionsBoatFilledOutlined />
                </div>
                <div>Fleet management</div>
              </div>
            </Link>
          )}
          {!isOnlyBroker && (
            <Link href="/app/central-agent/enquiries">
              <div
                className={classes({
                  "opacity-60": !pathname.startsWith(
                    "/app/central-agent/enquiries"
                  ),
                })}
              >
                <div className="text-xl mb-2">
                  <AddCommentOutlined />
                </div>
                <div>Enquiries</div>
              </div>
            </Link>
          )}
          <Link href="/app/contracts">
            <div
              className={classes({
                "opacity-60": !pathname.startsWith("/app/contracts"),
              })}
            >
              <div className="text-xl mb-2">
                <DescriptionOutlined />
              </div>
              <div>Contracts</div>
            </div>
          </Link>
          <Link href="/app/find-crew">
            <div
              className={classes({
                "opacity-60": !pathname.startsWith("/app/find-crew"),
              })}
            >
              <div className="text-xl mb-2">
                <AnchorOutlined />
              </div>
              <div>Find crew</div>
            </div>
          </Link>

          <Link href="/app/brokerage">
            <div
              className={classes({
                "opacity-60": !pathname.startsWith("/app/brokerage"),
              })}
            >
              <div className="text-xl mb-2">
                <LocalOfferOutlined />
              </div>
              <div>Yachts for sale</div>
            </div>
          </Link>
          <div className="h-px w-full bg-white bg-opacity-20"></div>
          <div>
            <Link href="/app/profile">
              <div>
                <div className="h-16 w-16 mb-4 bg-white rounded-full">
                  {
                    <PresignedImage
                      public
                      fileId={userInformation?.userProfile?.profile_image}
                      className="rounded-full h-full w-full object-cover object-center"
                      defaultSrc={defaultProfileImage.src}
                      alt="Personal profile image"
                    />
                  }
                </div>
                <div style={{ transform: "rotate(90deg)" }}>&#10093;</div>
              </div>
            </Link>
          </div>
          <Link href="/app/profile">
            <div
              className={classes({
                "opacity-60": !pathname.startsWith("/app/profile"),
              })}
            >
              <div className="text-xl mb-2">
                <SettingsOutlined />
              </div>
              <div>Settings</div>
            </div>
          </Link>
          <Link href="/app/auth/post-sign-out">
            <div className="opacity-60">
              <div className="text-xl mb-2">
                <LogoutOutlined />
              </div>
              <div>Log out</div>
            </div>
          </Link>
        </div>
      </div>
    </nav>
  );
};
